import { Accordion, Container } from 'components';
import { SEO } from 'components/seo';
import React, { useState } from 'react';
import { faqStyles } from 'style';

const panels = [
  {
    id: 0,
    title: 'What is the Maker Hand platform and how does it work?',
    description:
      'The Maker Hand platform is a way to find and educate 3D printing hobbyists and maker volunteers on how to build prosthetic hands and then connect them to those in need.',
  },
  {
    id: 1,
    title: 'How did Maker Hand get started?',
    description:
      'The Maker Hand started as a Master’s thesis in industrial design by Andrej Đukić. The four year developement process culminated in a decisive win in Cybathlon 2020, which is when work on the platform began.',
  },
  {
    id: 2,
    title: 'What is the Cybathlon?',
    description:
      'Cybathlon is an Olympics style competition organized by ETH Zurich that takes place every four years in which the biggest prosthetics companies (OttoBock, Ossur, etc.) as well as premier university research teams (Sorbonne, London Imperial College, TUM, Chalmers, etc.) apply to compete to see who can develop and demonstrate the most functional prosthetic devices.',
  },
  {
    id: 3,
    title: 'How is it possible the Maker Hand is free?',
    description:
      'While the cost of parts for a Maker Hand are about 30$, we do not charge for the prosthetic devices we provide because we are committed to providing everyone in need with a functional prosthesis for free. Our amazing makers procure the materials themselves and they get reimbursed by the Maker Hand foundation upon delivering the hands to amputees.',
  },
  {
    id: 4,
    title: 'Who can get a Maker Hand?',
    description:
      'Anyone above the age of five and anyone that has an amputation below the elbow including transcarpal, wrist amputation and partial hand is eligible to receive a prosthesis. Amputations at the elbow or above sadly cannot be addressed with a Maker Hand prosthesis.',
  },
  {
    id: 5,
    title: 'How does a Maker Hand work?',
    description:
      'The Maker Hand is a voluntary opening body-powered prosthesis. It is actuated by spreading out your shoulders which pulls on the control cable and in turn opens the hand while strong elastic bands pull the hand closed. The fingers also have an automatic locking system and they are coated with soft silicone for grip. The socket design allows for users with longer remaining forearms to harness their natural movement to rotate the prosthetic hand.',
  },
  {
    id: 6,
    title: 'What can you do with a Maker Hand?',
    description:
      'Maker Hand allows people to return to normal life activities, prepare food, maintain hygiene, use tools, cutlery, ride bicycles etc.',
  },
  {
    id: 7,
    title: 'Who can become a maker?',
    description:
      'Anyone who owns or has access to a 3D printer can be a volunteer although it would be preferable to have some experience in 3D modeling software.',
  },
  {
    id: 8,
    title: 'What kind of 3D printer do I need to make a Maker Hand?',
    description:
      'Almost any kind of 3D printer is suitable including low end printers like the Ender 3 from Creality.',
  },
  {
    id: 9,
    title: 'What materials do I need to make a Maker Hand?',
    description: 'Amazon list/Buy Kit/List of items to buy',
  },
  {
    id: 10,
    title: 'How long does it usually take to make a Maker Hand?',
    description:
      'The printing takes about a day or two depending on the size and assembly takes an afternoon.',
  },
];

const initalState = [
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
];

const Faq: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean[]>(initalState);

  return (
    <>
      <SEO title="FAQ" description="We answered some questions for you" />
      <Container>
        <div css={faqStyles.root}>
          <h2 css={faqStyles.title}>We answered some questions for you</h2>
          <section css={faqStyles.content}>
            {panels.map((panel) => (
              <Accordion
                key={panel.id + panel.title}
                {...panel}
                isOpen={isOpen[panel.id]}
                handleChangeAccordion={handleAccordionChange}
              />
            ))}
          </section>
        </div>
      </Container>
    </>
  );

  function handleAccordionChange(id: number) {
    if (isOpen[id]) {
      setIsOpen(initalState);
      return;
    }
    const mutated = [...initalState];
    mutated[id] = true;
    setIsOpen(mutated);
  }
};

export default Faq;
